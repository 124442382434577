import {Component} from '@angular/core';
import {ContenitoriBaseComponent} from '../contenitori-base.component';
import {Router} from '@angular/router';
import {GuastiService} from '../../core/guasti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-contenitori-verificati',
    templateUrl: './contenitori-verificati.component.html',
    styleUrls: ['./contenitori-verificati.component.css']
})
export class ContenitoriVerificatiComponent extends ContenitoriBaseComponent {
    constructor(
        guastiService: GuastiService,
        dialog: DialogService,
        router: Router
    ) {
        super(guastiService, router, 4, '/contenitori-verificati/', dialog);
    }

    onDownloadReport(id: number) {
        this.guastiService.getGuastoReportDownloadUrl(id.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }
}
