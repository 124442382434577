import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Magazziniere} from '../model/query/magazziniere';

@Injectable()
export class MagazzinieriService extends BaseService<Magazziniere> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('magazzinieri');
    }
}
