export class ChiusuraGuastoDto {
    dataFineRiparazione: Date;
    esitoPositivo: boolean;
    note: string;

    constructor(
        dataFineRiparazione: Date,
        esitoPositivo: boolean,
        note: string
    ) {
        this.dataFineRiparazione = dataFineRiparazione;
        this.esitoPositivo = esitoPositivo;
        this.note = note;
    }
}
