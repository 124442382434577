import {AbstractCommand} from './abstract-command';

export class GuastoDocumentoCommand extends AbstractCommand {
    guastoId: string;
    fileName: string;

    constructor(guastoId: string, fileName: string) {
        super();
        this.guastoId = guastoId;
        this.fileName = fileName;
    }
}
