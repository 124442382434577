import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {GuastiService} from '../../../core/guasti.service';
import {UtilsService} from '../../../core/utils.service';
import {Guasto} from '../../../model/query/guasto';
import {GuastoNota} from '../../../model/query/guasto-nota';
import {TipoRiparazione} from '../../../model/query/tipo-riparazione';
import {Riparazione} from '../../../model/query/riparazione';
import {GuastoDocumento} from '../../../model/query/guasto-documento';

@Component({
    selector: 'app-contenitore-verificato-detail',
    templateUrl: './contenitore-verificato-detail.component.html',
    styleUrls: ['./contenitore-verificato-detail.component.css']
})
export class ContenitoreVerificatoDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    guasto: Guasto;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    tipiGuasto: any = new Array<any>();
    selectedTipoRiparazione: TipoRiparazione;
    tipoRiparazione: string;
    note: Array<GuastoNota> = new Array<GuastoNota>();
    riparazioni: Array<Riparazione> = new Array<Riparazione>();
    showRiparazioneModal: boolean = false;
    selectedRiparazione: Riparazione;
    dataRiparazione: string;
    descrizione: string;
    matricolaValvola: string;
    dataPesata1: string;
    peso1: number;
    dataPesata2: string;
    peso2: number;
    nerTest: boolean;
    documenti = new Array<GuastoDocumento>();
    updatedOn: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public guastiService: GuastiService,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        public globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.guastiService.get(this.id).subscribe((guasto) => {
            this.guasto = guasto;
            this.updatedOn = this.utilsService.datetimeToField(this.guasto.updatedOn);
            this.initializeFormFields();
            this.guastiService.getTipiGuasto(this.id).subscribe(gtg => {
                for (const obj of gtg) {
                    this.tipiGuasto.push({tipoGuasto: obj.tipoGuasto.nome, descrizione: obj.descrizione});
                }
            });
            this.guastiService.getNote(this.id).subscribe(note => {
                this.note = note;
            });
            this.refreshDocumenti();
            this.loadRiparazioni().subscribe();
        });
    }

    loadRiparazioni(): Observable<Array<Riparazione>> {
        return this.guastiService.getRiparazioni(this.id).pipe(
            map((a: Array<Riparazione>) => {
                this.riparazioni = a;
                if (this.riparazioni.length > 0) {
                    this.selectedRiparazione = this.riparazioni[0];
                }
                return a;
            })
        );
    }

    onSubmit() {
        this.router.navigate(['/contenitori-verificati']);
    }

    createForm(): void {
        const formGroup = {
            tipologia: [{ value: '', disabled: true }],
            modello: [{ value: '', disabled: true }],
            matricola: [{ value: '', disabled: true }],
            createdOn: [{ value: '', disabled: true }],
            dataApertura: [{ value: '', disabled: true }],
            utenteApertura: [{ value: '', disabled: true }],
            notaApertura: [{ value: '', disabled: true }],
            dataInizioRiparazione: [{ value: '', disabled: true }],
            utenteInizioRiparazione: [{ value: '', disabled: true }],
            notaAperturaRiparazione: [{ value: '', disabled: true }],
            dataFineRiparazione: [{ value: '', disabled: true }],
            utenteFineRiparazione: [{ value: '', disabled: true }],
            notaChiusuraGuasto: [{ value: '', disabled: true }],
            dataVerifica: [{ value: '', disabled: true }],
            utenteVerifica: [{ value: '', disabled: true }],
            notaVerifica: [{ value: '', disabled: true }],
            esitoPositivo: [{ value: false, disabled: true }],
            capovolgimento: [{ value: false, disabled: true }],
            vent: [{ value: false, disabled: true }],
            ispezioneVisiva: [{ value: false, disabled: true }],
            verificataAssenzaAcqua: [{ value: false, disabled: true }],
            verificaFlussi: [{ value: false, disabled: true }],
            bonifica: [{ value: false, disabled: true }],
            loader: [{ value: '', disabled: true }],
            updatedOn: [{ value: '', disabled: true }]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(): void {
        let formValues;

        formValues = {
            tipologia: this.guastiService.TIPO_CONTENITORE[this.guasto.tipologia],
            modello: this.guasto.modello,
            matricola: this.guasto.matricola,
            createdOn: this.utilsService.dateToField(this.guasto.createdOn),
            dataApertura: this.utilsService.dateToField(this.guasto.dataApertura),
            utenteApertura: this.guasto.utenteApertura,
            notaApertura: this.guasto.notaAperturaGuasto || '',
            dataInizioRiparazione: this.utilsService.dateToField(this.guasto.dataInizioRiparazione),
            utenteInizioRiparazione: this.guasto.utenteInizioRiparazione,
            notaAperturaRiparazione: this.guasto.notaAperturaRiparazione || '',
            dataFineRiparazione: this.utilsService.dateToField(this.guasto.dataFineRiparazione),
            utenteFineRiparazione: this.guasto.utenteFineRiparazione,
            notaChiusuraGuasto: this.guasto.notaChiusuraGuasto || '',
            dataVerifica: this.utilsService.dateToField(this.guasto.dataVerifica),
            utenteVerifica: this.guasto.utenteVerifica,
            notaVerifica: this.guasto.notaVerifica || '',
            esitoPositivo: this.guasto.esitoPositivo,
            capovolgimento: !!this.guasto.capovolgimento,
            vent: !! this.guasto.vent,
            ispezioneVisiva: !! this.guasto.ispezioneVisiva,
            verificataAssenzaAcqua: !! this.guasto.verificataAssenzaAcqua,
            verificaFlussi: !! this.guasto.verificaFlussi,
            bonifica: !! this.guasto.bonifica,
            loader: this.guasto.loader || '',
            updatedOn: this.utilsService.datetimeToField(this.guasto.updatedOn)
        };
        this.form.setValue(formValues);
    }

    onShowRiparazione(): void {
        this.showRiparazioneModal = true;
        this.selectedTipoRiparazione = this.selectedRiparazione.tipoRiparazione;
        this.tipoRiparazione = this.selectedRiparazione.tipoRiparazione.nome;
        this.dataRiparazione = this.utilsService.dateToField(this.selectedRiparazione.dataRiparazione);
        this.descrizione = this.selectedRiparazione.descrizione;
        this.matricolaValvola = this.selectedRiparazione.matricolaValvola;
        this.dataPesata1 = this.utilsService.datetimeToField(this.selectedRiparazione.dataPesata1);
        this.peso1 = this.selectedRiparazione.peso1;
        this.dataPesata2 = this.utilsService.datetimeToField(this.selectedRiparazione.dataPesata2);
        this.peso2 = this.selectedRiparazione.peso2;
        this.nerTest = this.selectedRiparazione.nerTest;
    }

    onDownloadDocumento(documentoId: number) {
        this.guastiService.getDocumentoDownloadUrl(documentoId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    refreshDocumenti() {
        this.guastiService.getDocumenti(this.id).subscribe(documenti => {
            this.documenti = documenti;
        });
    }

    showDescrizione(): boolean {
        return this.selectedTipoRiparazione &&
            (this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_BOMBOLA_ALTRO || this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_ALTRO
                || this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_ALTRO);
    }

    showMatricola(): boolean {
        return this.selectedTipoRiparazione && this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_BOMBOLA_SOST_VALVOLA;
    }

    showNumeroSeriale(): boolean {
        return this.selectedTipoRiparazione && (
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_VALVOLA_SECONDARIA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_VALVOLA_SECONDARIA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_INNESTO_CARICA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_INNESTO_CARICA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_EROGATORE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_EROGATORE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_VALVOLE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_VALVOLE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_BASE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_BASE);
    }
}
