import {ReportGuastiCommand} from './report-guasti-command';

export class ReportGuastiDownloadCommand extends ReportGuastiCommand {
    reportType: string;

    constructor(
        from: Date,
        to: Date,
        tipoContenitore: number,
        modello: string,
        matricola: string,
        reportType: string
    ) {
        super(from, to, tipoContenitore, modello, matricola);
        this.reportType = reportType;
    }
}
