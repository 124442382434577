import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Manutentore} from '../../model/query/manutentore';
import {Router} from '@angular/router';
import {ManutentoriService} from '../../core/manutentori.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-manutentori',
    templateUrl: './manutentori.component.html',
    styleUrls: ['./manutentori.component.css']
})
export class ManutentoriComponent extends DatagridComponent<Manutentore> {

    constructor(
        private manutentoriService: ManutentoriService,
        dialog: DialogService,
        router: Router
    ) {
        super(manutentoriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/manutentori/';
    }

}
