import {Component} from '@angular/core';
import {ContenitoriBaseComponent} from '../contenitori-base.component';
import {Router} from '@angular/router';
import {GuastiService} from '../../core/guasti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-contenitori-in-riparazione',
    templateUrl: './contenitori-in-riparazione.component.html',
    styleUrls: ['./contenitori-in-riparazione.component.css']
})
export class ContenitoriInRiparazioneComponent extends ContenitoriBaseComponent {

    constructor(
        guastiService: GuastiService,
        dialog: DialogService,
        router: Router
    ) {
        super(guastiService, router, 2, '/contenitori-in-riparazione/', dialog);
    }
}
