import {AbstractCommand} from './abstract-command';

export class GuastoNotaCommand extends AbstractCommand {
    guastoId: number;
    testo: string;
    utente: string;

    constructor(guastoId: number, testo: string) {
        super();
        this.guastoId = guastoId;
        this.testo = testo;
    }
}
