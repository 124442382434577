import {AbstractCommand} from './abstract-command';

export class RiparazioneCommand extends AbstractCommand {
    private guastoId: number;
    private tipoRiparazioneId: number;
    private descrizione: string;
    private dataRiparazione: Date;
    private matricolaValvola: string;
    private dataPesata1: Date;
    private peso1: number;
    private dataPesata2: Date;
    private peso2: number;
    private nerTest: boolean;
    
    constructor(
        guastoId: number,
        tipoRiparazioneId: number,
        descrizione: string,
        dataRiparazione: Date,
        matricolaValvola: string,
        dataPesata1: Date,
        peso1: number,
        dataPesata2: Date,
        peso2: number,
        nerTest: boolean
    ) {
        super();
        this.guastoId = guastoId;
        this.tipoRiparazioneId = tipoRiparazioneId;
        this.descrizione = descrizione;
        this.dataRiparazione = dataRiparazione;
        this.matricolaValvola = matricolaValvola;
        this.dataPesata1 = dataPesata1;
        this.peso1 = peso1;
        this.dataPesata2 = dataPesata2;
        this.peso2 = peso2;
        this.nerTest = nerTest;
    }
}
