import {AccountCommand} from './account-command';

export class ManutentoreCreateCommand extends AccountCommand {
    manutentore: any;

    constructor(nome: string, cognome: string, username: string, email: string, esterno: boolean) {
        super(username, email);
        this.manutentore = {
            'nome': nome,
            'cognome': cognome,
            'esterno': esterno
        };
    }
}
