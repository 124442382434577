import {AbstractCommand} from './abstract-command';

export class GuastoCommand extends AbstractCommand {
    private capovolgimento: boolean;
    private ispezioneVisiva: boolean;
    private verificataAssenzaAcqua: boolean;
    private verificaFlussi: boolean;
    private bonifica: boolean;
    private vent: boolean;

    constructor(
        capovolgimento: boolean,
        ispezioneVisiva: boolean,
        verificataAssenzaAcqua: boolean,
        verificaFlussi: boolean,
        bonifica: boolean,
        vent: boolean
    ) {
        super();
        this.capovolgimento = capovolgimento;
        this.ispezioneVisiva = ispezioneVisiva;
        this.verificataAssenzaAcqua = verificataAssenzaAcqua;
        this.verificaFlussi = verificaFlussi;
        this.bonifica = bonifica;
        this.vent = vent;
    }
}
