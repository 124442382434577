import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import {ReportGuastiCommand} from '../../../model/command/report-guasti-command';
import {ReportGuastiDownloadCommand} from '../../../model/command/report-guasti-download-command';
import {ReportGuastiResponse} from '../../../model/query/report-guasti-response';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-report-guasti',
    templateUrl: './report-guasti.component.html',
    styleUrls: ['./report-guasti.component.css']
})
export class ReportGuastiComponent {
    subscription: Subscription;
    loading = true;
    form: any;

    dateFrom: string;
    dateTo: string;

    response: ReportGuastiResponse;

    constructor(
        private formBuilder: FormBuilder,
        private reportsService: ReportsService,
        public utilsService: UtilsService,
        private dialog: DialogService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.initForm();
    }

    refresh() {
        this.loading = true;

        const formValue = this.form.getRawValue();
        const command = new ReportGuastiCommand(
            this.utilsService.fieldToDate(formValue.dateFrom),
            this.utilsService.fieldToDate(formValue.dateTo),
            formValue.tipologia ? Number(formValue.tipologia) : null,
            formValue.modello ? formValue.modello : null,
            formValue.matricola ? formValue.matricola : null
        );

        this.reportsService.fetchReportGuasti(command).subscribe((result: ReportGuastiResponse) => {
            this.response = result;
            this.loading = false;
        });
    }

    createForm(): void {
        const formGroup = {
            dateFrom: '',
            dateTo: '',
            tipologia: '',
            modello: '',
            matricola: ''
        };

        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            dateFrom: '',
            dateTo: '',
            tipologia: '',
            modello: '',
            matricola: ''
        };

        this.form.setValue(formValues);
    }


    openDownloadAlert() {
        this.dialog.confirm({
            title: 'Scarica file',
            content: 'Scegli il formato da scaricare',
            acceptText: 'Pdf',
            cancelText: 'Excel',
            acceptType: 'warning',
            iconShape: null
        }).subscribe((result: boolean) => {
            if (result) {
                this.onSubmit("pdf");
            } else {
                this.onSubmit("xls");
            }
        });
    }

    onSubmit(reportType: string) {
        const formValue = this.form.getRawValue();

        if (reportType) {
            const command = new ReportGuastiDownloadCommand(
                this.utilsService.fieldToDate(formValue.dateFrom),
                this.utilsService.fieldToDate(formValue.dateTo),
                formValue.tipologia ? Number(formValue.tipologia) : null,
                formValue.modello ? formValue.modello : null,
                formValue.matricola ? formValue.matricola : null,
                reportType
            );
            this.reportsService.getReportGuastiDownloadLink(command).subscribe((url) => {
                if (url) {
                    // window.location.href = url;
                    window.open(url);
                }
            });
        } else {
            this.refresh();
        }
    }
}
