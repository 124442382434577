import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {QueryResult} from '../model/query/query-result';
import {ResultUrl} from '../model/result-url';
import {Guasto} from '../model/query/guasto';
import {GuastoDocumento} from '../model/query/guasto-documento';
import {GuastoTipoGuasto} from '../model/query/guasto-tipo-guasto';
import {TipoGuasto} from '../model/query/tipo-guasto';
import {TipoRiparazione} from '../model/query/tipo-riparazione';
import {Riparazione} from '../model/query/riparazione';
import {AperturaGuastoDto} from '../model/command/apertura-guasto-dto';
import {ChiusuraGuastoDto} from '../model/command/chiusura-guasto-dto';
import {VerificaGuastoDto} from '../model/command/verifica-guasto-dto';
import {InizioRiparazioneGuastoDto} from '../model/command/inizio-riparazione-guasto-dto';
import {RiparazioneCommand} from '../model/command/riparazione-command';
import {TipoContenitore} from '../model/tipo-contenitore';
import {StatoGuasto} from '../model/stato-guasto';
import {GuastoNota} from '../model/query/guasto-nota';
import {GuastoNotaCommand} from '../model/command/guasto-nota-command';
import {GuastoDocumentoCommand} from '../model/command/guasto-documento-command';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class GuastiService extends BaseService<Guasto> {

    readonly TIPO_CONTENITORE: typeof TipoContenitore = TipoContenitore;
    readonly STATO_GUASTO: typeof StatoGuasto = StatoGuasto;

    private storageBasePath: string = environment.storageUrl;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('guasti');
    }

    getTipiGuasto(idGuasto: string): Observable<GuastoTipoGuasto[]> {
        return this.httpClient.get<GuastoTipoGuasto[]>(this.apiUrl + '/' + idGuasto + '/tipi_guasto').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getRiparazioni(idGuasto: string): Observable<Riparazione[]> {
        return this.httpClient.get<Riparazione[]>(this.apiUrl + '/' + idGuasto + '/riparazioni').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getElencoTipiGuasto(tipo: TipoContenitore): Observable<QueryResult<TipoGuasto>> {
        return this.httpClient.get<QueryResult<TipoGuasto>>(this.apiUrl + '/elenco_tipi_guasto/' + this.TIPO_CONTENITORE[tipo]).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getElencoTipiRiparazione(tipo: TipoContenitore): Observable<QueryResult<TipoRiparazione>> {
        return this.httpClient.get<QueryResult<TipoGuasto>>(this.apiUrl + '/elenco_tipi_riparazione/' + this.TIPO_CONTENITORE[tipo]).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    apri(guastoId: string, dto: AperturaGuastoDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + guastoId + '/apri', dto).pipe(
            catchError(this.handleError(this.apiUrl + ' - apri', null))
        );
    }

    iniziaRiparazione(guastoId: string, dto: InizioRiparazioneGuastoDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + guastoId + '/inizia-riparazione', dto).pipe(
            catchError(this.handleError(this.apiUrl + ' - inizia-riparazione', null))
        );
    }

    aggiungiRiparazione(guastoId: string, riparazione: RiparazioneCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + guastoId + '/riparazioni', riparazione).pipe(
            catchError(this.handleError(this.apiUrl + ' - riparazioni', null))
        );
    }

    aggiornaRiparazione(guastoId: string, riparazioneId: string, riparazione: RiparazioneCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + guastoId + '/riparazioni/' + riparazioneId, riparazione).pipe(
            catchError(this.handleError(this.apiUrl + ' - riparazioni', null))
        );
    }

    eliminaRiparazione(guastoId: string, riparazioneId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + guastoId + '/riparazioni/' + riparazioneId).pipe(
            catchError(this.handleError(this.apiUrl + ' - riparazioni', null))
        );
    }

    chiudi(guastoId: string, dto: ChiusuraGuastoDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + guastoId + '/chiudi', dto).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    verifica(guastoId: string, dto: VerificaGuastoDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + guastoId + '/verifica', dto).pipe(
            catchError(this.handleError(this.apiUrl + ' - verifica', null))
        );
    }

    aggiungiNota(idGuasto: string, command: GuastoNotaCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + idGuasto + '/note', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<number> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError('aggiungiNota', null))
        );
    }

    getNote(id: string): Observable<Array<GuastoNota>> {
        return this.httpClient.get<Array<GuastoNota>>(this.apiUrl + '/' + id + '/note').pipe(
            catchError(this.handleError(this.apiUrl + '- : get note', null))
        );
    }
    
    getGuastoReportDownloadUrl(id: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/report').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getGuastoReportDownloadUrl', null))
        );
    }

    aggiungiDocumento(guastoId: string, file: File, command: GuastoDocumentoCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + guastoId + '/documenti', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<GuastoDocumento> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return this.httpClient.get<GuastoDocumento>(this.apiUrl + '/' + guastoId + '/documenti/' + id);
                } else {
                    return of(null);
                }
            }),
            switchMap((documento: GuastoDocumento) => {
                if (documento != null) {
                    return this.httpClient.put(this.storageBasePath + documento.fileUrl, file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDocumento', null))
        );
    }

    eliminaDocumento(guastoId: string, documentoId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + guastoId + '/documenti/' + documentoId).pipe(
            catchError(this.handleError(this.apiUrl + ' - eliminaDocumento', null))
        );
    }

    getDocumenti(id: string): Observable<Array<GuastoDocumento>> {
        return this.httpClient.get<Array<GuastoDocumento>>(this.apiUrl + '/' + id + '/documenti').pipe(
            catchError(this.handleError(this.apiUrl + ' - getDocumenti', null))
        );
    }

    getDocumentoDownloadUrl(documentoId: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/documenti/' + documentoId + '/download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDocumentoDownloadUrl', null))
        );
    }
    
}
