import {Observable, of as observableOf} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Account} from '../model/query/account';
import {User} from '../model/query/user';

@Injectable()
export class MeService extends BaseService<Account> {
    me: User;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('me');
    }

    public changePassword(newPassword: string): Observable<any> {
        return this.httpClient.post<any>(this.apiUrl + '/reset-password', {password: newPassword}).pipe(
            catchError(this.handleError('changePassword', undefined))
        );
    }

    public getMe(): Observable<User> {
        if (this.me) {
            return observableOf(this.me);
        } else {
            return this.httpClient.get<User>(this.apiUrl).pipe(
                tap(user => this.me = user),
                catchError(this.handleError(this.apiUrl + ' - get', null))
            );
        }
    }

    public setMe(me: User) {
        this.me = me;
    }
}
