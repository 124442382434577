import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../core/auth.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: []
})
export class SidebarComponent implements OnInit {
    collapsedVerticalNav: boolean = false; //Variabile che setta lo stato di collapsed iniziale alla vertical navbar laterale di sinistra
    
    constructor(
        public authService: AuthService
    ) {
    }

    ngOnInit() {
    }
}
