import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {AddettoConsegna} from '../model/query/addetto-consegna';

@Injectable()
export class AddettiConsegnaService extends BaseService<AddettoConsegna> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('addetti-consegna');
    }
}
