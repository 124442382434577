import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ClarityModule} from '@clr/angular';
import {CoreModule} from '../core/core.module';
import {DialogComponent} from './dialog/dialog.component';
import {HeaderComponent} from './layout/header/header.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {MainComponent} from './layout/main/main.component';
import {AmministratoriComponent} from './amministratori/amministratori.component';
import {AmministratoreDetailComponent} from './amministratori/amministratore-detail/amministratore-detail.component';
import {AddettiConsegnaComponent} from './addetti-consegna/addetti-consegna.component';
import {AddettoConsegnaDetailComponent} from './addetti-consegna/addetto-consegna-detail/addetto-consegna-detail.component';
import {AddettiProduzioneComponent} from './addetti-produzione/addetti-produzione.component';
import {AddettoProduzioneDetailComponent} from './addetti-produzione/addetto-produzione-detail/addetto-produzione-detail.component';
import {ResponsabiliProduzioneComponent} from './responsabili-produzione/responsabili-produzione.component';
import {ResponsabileProduzioneDetailComponent} from './responsabili-produzione/responsabile-produzione-detail/responsabile-produzione-detail.component';
import {MagazzinieriComponent} from './magazzinieri/magazzinieri.component';
import {MagazziniereDetailComponent} from './magazzinieri/magazziniere-detail/magazziniere-detail.component';
import {ManutentoriComponent} from './manutentori/manutentori.component';
import {ManutentoreDetailComponent} from './manutentori/manutentore-detail/manutentore-detail.component';
import {SharedModule} from '../shared/shared.module';
import {ReportGuastiComponent} from './reports/report-guasti/report-guasti.component';
import {ReplaceUnderscorePipe} from './common/replace-underscore.pipe';
import {TipologiaContenitoreFilterComponent} from './tipologia-contenitore.filter.component';
import {StatoGuastoFilterComponent} from './stato-guasto.filter.component';
import {DateFilterComponent} from './date.filter.component';
import {BooleanFilterComponent} from './boolean.filter.component';
import {SearchWidgetComponent} from './search-widget.component';
import {PasswordComponent} from './password/password.component';
import {ContenitoriGuastiComponent} from './contenitori-guasti/contenitori-guasti.component';
import {ContenitoreGuastoDetailComponent} from './contenitori-guasti/contenitore-guasto-detail/contenitore-guasto-detail.component';
import {ContenitoriDaRiparareComponent} from './contenitori-da-riparare/contenitori-da-riparare.component';
import {ContenitoreDaRiparareDetailComponent} from './contenitori-da-riparare/contenitore-da-riparare-detail/contenitore-da-riparare-detail.component';
import {ContenitoriInRiparazioneComponent} from './contenitori-in-riparazione/contenitori-in-riparazione.component';
import {ContenitoreInRiparazioneDetailComponent} from './contenitori-in-riparazione/contenitore-in-riparazione-detail/contenitore-in-riparazione-detail.component';
import {ContenitoriDaVerificareComponent} from './contenitori-da-verificare/contenitori-da-verificare.component';
import {ContenitoreDaVerificareDetailComponent} from './contenitori-da-verificare/contenitore-da-verificare-detail/contenitore-da-verificare-detail.component';
import {ContenitoriVerificatiComponent} from './contenitori-verificati/contenitori-verificati.component';
import {ContenitoreVerificatoDetailComponent} from './contenitori-verificati/contenitore-verificato-detail/contenitore-verificato-detail.component';
import {ContenitoriComponent} from './contenitori/contenitori.component';
import {ContenitoreDetailComponent} from './contenitori/contenitore-detail/contenitore-detail.component';

@NgModule({
    declarations: [
        DialogComponent,
        HeaderComponent,
        SidebarComponent,
        MainComponent,
        AmministratoriComponent,
        AmministratoreDetailComponent,
        AddettiConsegnaComponent,
        AddettoConsegnaDetailComponent,
        AddettiProduzioneComponent,
        AddettoProduzioneDetailComponent,
        ResponsabiliProduzioneComponent,
        ResponsabileProduzioneDetailComponent,
        MagazzinieriComponent,
        MagazziniereDetailComponent,
        ManutentoriComponent,
        ManutentoreDetailComponent,
        ReportGuastiComponent,
        ReplaceUnderscorePipe,
        TipologiaContenitoreFilterComponent,
        StatoGuastoFilterComponent,
        DateFilterComponent,
        BooleanFilterComponent,
        SearchWidgetComponent,
        PasswordComponent,
        ContenitoriGuastiComponent,
        ContenitoreGuastoDetailComponent,
        ContenitoriDaRiparareComponent,
        ContenitoreDaRiparareDetailComponent,
        ContenitoriInRiparazioneComponent,
        ContenitoreInRiparazioneDetailComponent,
        ContenitoriDaVerificareComponent,
        ContenitoreDaVerificareDetailComponent,
        ContenitoriVerificatiComponent,
        ContenitoreVerificatoDetailComponent,
        ContenitoriComponent,
        ContenitoreDetailComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ClarityModule,
        CoreModule,
        SharedModule
    ],
    exports: [
        CoreModule,
        SharedModule,
        DialogComponent,
        HeaderComponent,
        SidebarComponent,
        MainComponent,
        AmministratoriComponent,
        AmministratoreDetailComponent,
        AddettiConsegnaComponent,
        AddettoConsegnaDetailComponent,
        AddettiProduzioneComponent,
        AddettoProduzioneDetailComponent,
        ResponsabiliProduzioneComponent,
        ResponsabileProduzioneDetailComponent,
        MagazzinieriComponent,
        MagazziniereDetailComponent,
        ManutentoriComponent,
        ManutentoreDetailComponent,
        TipologiaContenitoreFilterComponent,
        StatoGuastoFilterComponent,
        DateFilterComponent,
        BooleanFilterComponent,
        SearchWidgetComponent,
        PasswordComponent,
        ContenitoriGuastiComponent,
        ContenitoreGuastoDetailComponent,
        ContenitoriDaRiparareComponent,
        ContenitoreDaRiparareDetailComponent,
        ContenitoriInRiparazioneComponent,
        ContenitoreInRiparazioneDetailComponent,
        ContenitoriDaVerificareComponent,
        ContenitoreDaVerificareDetailComponent,
        ContenitoriVerificatiComponent,
        ContenitoreVerificatoDetailComponent,
        ContenitoriComponent,
        ContenitoreDetailComponent
    ]
})
export class UiModule {
}
