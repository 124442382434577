import { Component, OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Action } from '../../../model/action';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { GuastiService } from '../../../core/guasti.service';
import { UtilsService } from '../../../core/utils.service';
import { AuthService } from '../../../core/auth.service';
import { Guasto } from '../../../model/query/guasto';
import { GuastoCommand } from '../../../model/command/guasto-command';
import { GuastoNota } from '../../../model/query/guasto-nota';
import { TipoRiparazione } from '../../../model/query/tipo-riparazione';
import { Riparazione } from '../../../model/query/riparazione';
import { RiparazioneCommand } from '../../../model/command/riparazione-command';
import { ChiusuraGuastoDto } from '../../../model/command/chiusura-guasto-dto';
import { GuastoNotaCommand } from '../../../model/command/guasto-nota-command';
import { GuastoDocumento } from '../../../model/query/guasto-documento';
import { GuastoDocumentoCommand } from '../../../model/command/guasto-documento-command';

@Component({
    selector: 'app-contenitore-in-riparazione-detail',
    templateUrl: './contenitore-in-riparazione-detail.component.html',
    styleUrls: ['./contenitore-in-riparazione-detail.component.css']
})
export class ContenitoreInRiparazioneDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    guasto: Guasto;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    modalUpdate: boolean = false;
    modalAdd: boolean = false;
    tipiGuasto: any = new Array<any>();
    tipiRiparazione: Array<TipoRiparazione> = new Array<TipoRiparazione>();
    note: Array<GuastoNota> = new Array<GuastoNota>();
    selectedTipoRiparazione: TipoRiparazione;
    riparazioni: Array<Riparazione> = new Array<Riparazione>();
    showRiparazioneModal: boolean = false;
    showChiusuraModal: boolean = false;
    selectedRiparazione: Riparazione;
    dataRiparazione: string;
    descrizione: string;
    matricolaValvola: string;
    dataPesata1: Date;
    peso1: string;
    dataPesata2: Date;
    peso2: string;
    nerTest: boolean;
    dataFineRiparazione: string;
    esitoPositivo: boolean;
    fineRiparazione: boolean = false;
    noteChiusura: string;
    documento: File;
    documenti = new Array<GuastoDocumento>();
    riparazioneDaCercare: string;
    updatedOn: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public guastiService: GuastiService,
        private utilsService: UtilsService,
        public authService: AuthService,
        private formBuilder: FormBuilder,
        public globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.guastiService.get(this.id).subscribe((guasto) => {
            this.guasto = guasto;
            this.updatedOn = this.utilsService.datetimeToField(this.guasto.updatedOn);
            this.initializeFormFields();
            this.guastiService.getTipiGuasto(this.id).subscribe(gtg => {
                for (const obj of gtg) {
                    this.tipiGuasto.push({ tipoGuasto: obj.tipoGuasto.nome, descrizione: obj.descrizione });
                }
            });
            this.guastiService.getNote(this.id).subscribe(note => {
                this.note = note;
            });
            this.refreshDocumenti();
            this.guastiService.getElencoTipiRiparazione(guasto.tipologia).subscribe(tipiRiparazione => {
                this.tipiRiparazione = tipiRiparazione.objects;
                this.tipiRiparazione.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
            });
            this.loadRiparazioni().subscribe();
        });
    }

    loadRiparazioni(): Observable<Array<Riparazione>> {
        return this.guastiService.getRiparazioni(this.id).pipe(
            map((a: Array<Riparazione>) => {
                this.riparazioni = a;
                if (this.riparazioni.length > 0) {
                    this.selectedRiparazione = this.riparazioni[0];
                }
                return a;
            })
        );
    }

    onSubmit() {
        if (!this.fineRiparazione) {
            this.submitButton = ClrLoadingState.LOADING;

            const formValue = this.form.getRawValue();

            const command = new GuastoCommand(
                formValue.capovolgimento,
                formValue.ispezioneVisiva,
                formValue.verificataAssenzaAcqua,
                formValue.verificaFlussi,
                formValue.bonifica,
                formValue.vent);

            this.guastiService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Aggiornamento guasto effettuato!';
                    this.alertClosed = false;
                    if (formValue.note) {
                        this.submitButton = ClrLoadingState.LOADING;

                        const commandNota = new GuastoNotaCommand(this.guasto.id, formValue.note);
                        this.guastiService.aggiungiNota(this.id, commandNota).subscribe((res) => {
                            this.submitButton = ClrLoadingState.DEFAULT;
                            this.router.navigate(['/contenitori-in-riparazione']);
                        });
                    } else {
                        this.router.navigate(['/contenitori-in-riparazione']);
                    }

                }
            });
        } else {
            this.fineRiparazione = false;
        }
    }

    doFineRiparazione(): void {
        this.showChiusuraModal = true;
        this.dataFineRiparazione = this.utilsService.dateToField(new Date());
        this.esitoPositivo = false;
        this.fineRiparazione = true;
    }

    chiudiGuasto(): void {
        this.submitButton = ClrLoadingState.LOADING;

        let dto: ChiusuraGuastoDto = new ChiusuraGuastoDto(this.utilsService.fieldToDate(this.dataFineRiparazione), this.esitoPositivo, this.noteChiusura);

        this.guastiService.chiudi(this.id, dto).subscribe((res) => {
            this.submitButton = ClrLoadingState.DEFAULT;
            if (!res) {
                this.alertMessage = 'Guasto chiuso!';
                this.alertClosed = false;
                this.router.navigate(['/contenitori-in-riparazione']);
            }
        });
    }

    createForm(): void {
        const formGroup = {
            tipologia: [{ value: '', disabled: true }],
            modello: [{ value: '', disabled: true }],
            matricola: [{ value: '', disabled: true }],
            createdOn: [{ value: '', disabled: true }],
            dataApertura: [{ value: '', disabled: true }],
            utenteApertura: [{ value: '', disabled: true }],
            notaApertura: [{ value: '', disabled: true }],
            note: '',
            dataInizioRiparazione: [{ value: '', disabled: true }],
            utenteInizioRiparazione: [{ value: '', disabled: true }],
            notaAperturaRiparazione: [{ value: '', disabled: true }],
            capovolgimento: false,
            vent: false,
            ispezioneVisiva: false,
            verificataAssenzaAcqua: false,
            verificaFlussi: false,
            bonifica: false
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(): void {
        let formValues;

        formValues = {
            tipologia: this.guastiService.TIPO_CONTENITORE[this.guasto.tipologia],
            modello: this.guasto.modello,
            matricola: this.guasto.matricola,
            createdOn: this.utilsService.dateToField(this.guasto.createdOn),
            dataApertura: this.utilsService.dateToField(this.guasto.dataApertura),
            utenteApertura: this.guasto.utenteApertura,
            notaApertura: this.guasto.notaAperturaGuasto || '',
            note: '',
            dataInizioRiparazione: this.utilsService.dateToField(this.guasto.dataInizioRiparazione),
            utenteInizioRiparazione: this.guasto.utenteInizioRiparazione,
            notaAperturaRiparazione: this.guasto.notaAperturaRiparazione || '',
            capovolgimento: !!this.guasto.capovolgimento,
            vent: !!this.guasto.vent,
            ispezioneVisiva: !!this.guasto.ispezioneVisiva,
            verificataAssenzaAcqua: !!this.guasto.verificataAssenzaAcqua,
            verificaFlussi: !!this.guasto.verificaFlussi,
            bonifica: !!this.guasto.bonifica
        };
        this.form.setValue(formValues);
    }

    onNuovaRiparazione(): void {
        this.initializeRiparazioniModal();
        this.modalAdd = true;
        this.modalUpdate = true;
        this.selectedTipoRiparazione = null;
        this.dataRiparazione = this.utilsService.dateToField(new Date());
        this.descrizione = '';
        this.matricolaValvola = '';
        this.dataPesata1 = new Date();
        this.peso1 = "0";
        this.dataPesata2 = new Date();
        this.peso2 = "0";
        this.nerTest = false;
    }

    initializeRiparazioniModal(): void {
        this.showRiparazioneModal = true;
        this.selectedTipoRiparazione = null;
        this.riparazioneDaCercare = '';
    }

    onDeleteRiparazione(): void {
        this.guastiService.eliminaRiparazione(this.id, this.selectedRiparazione.id.toString()).subscribe((res) => {
            this.submitButton = ClrLoadingState.DEFAULT;
            if (!res) {
                this.alertMessage = 'Riparazione eliminata!';
                this.alertClosed = false;
                this.loadRiparazioni().subscribe(a => this.updateVerificaFlussiStatus());
            }
        });
    }

    onShowRiparazione(aggiorna: boolean): void {
        this.initializeRiparazioniModal();
        this.modalAdd = false;
        this.modalUpdate = aggiorna;
        this.selectedTipoRiparazione = this.tipiRiparazione.find(t => t.id == this.selectedRiparazione.tipoRiparazione.id);
        this.dataRiparazione = this.utilsService.dateToField(this.selectedRiparazione.dataRiparazione);
        this.descrizione = this.selectedRiparazione.descrizione;
        this.matricolaValvola = this.selectedRiparazione.matricolaValvola;
        this.dataPesata1 = this.selectedRiparazione.dataPesata1;
        this.peso1 = this.selectedRiparazione.peso1.toString();
        this.dataPesata2 = this.selectedRiparazione.dataPesata2;
        this.peso2 = this.selectedRiparazione.peso2.toString();
        this.nerTest = this.selectedRiparazione.nerTest;
    }

    addRiparazione(): void {
        this.closeRiparazioneModal();

        let command = new RiparazioneCommand(
            this.guasto.id,
            this.selectedTipoRiparazione.id,
            this.descrizione,
            this.utilsService.fieldToDate(this.dataRiparazione),
            this.matricolaValvola,
            this.dataPesata1,
            Number(this.peso1),
            this.dataPesata2,
            Number(this.peso2),
            this.nerTest
        );

        let o: Observable<any>;

        if (this.modalAdd) {
            o = this.guastiService.aggiungiRiparazione(this.id, command);
        } else {
            o = this.guastiService.aggiornaRiparazione(this.id, this.selectedRiparazione.id.toString(), command);
        }
        o.subscribe((res) => {
            this.submitButton = ClrLoadingState.DEFAULT;
            if (!res) {
                this.alertMessage = this.modalAdd ? 'Aggiunta riparazione effettuata!' : 'Aggiornamento riparazione effettuata!';
                this.alertClosed = false;
                this.loadRiparazioni().subscribe(a => this.updateVerificaFlussiStatus());
            }
        });
    }

    closeRiparazioneModal(): void {
        this.showRiparazioneModal = false;
    }

    updateVerificaFlussiStatus(): void {
        const formValues = {
            verificaFlussi: this.riparazioni.findIndex(r => r.tipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_BOMBOLA_SOST_VALVOLA ||
                r.tipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_EROGATORE ||
                r.tipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_RIPARAZ_PERDITE ||
                r.tipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_EROGATORE ||
                r.tipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_RIPARAZ_PERDITE) >= 0
        };
        this.form.patchValue(formValues);
    }

    onPesoChange(event): void {
        this.nerTest = Number(this.peso1) != 0 && Number(this.peso2) != 0 && Number(this.peso1) - Number(this.peso2) <= 900;
    }

    canAddRiparazione(): boolean {
        return this.selectedTipoRiparazione &&
            ((this.selectedTipoRiparazione.id !== this.globals.TIPO_RIPARAZIONE_BOMBOLA_SOST_VALVOLA &&
                this.selectedTipoRiparazione.id !== this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_VALVOLE &&
                this.selectedTipoRiparazione.id !== this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_VALVOLE &&
                this.selectedTipoRiparazione.id !== this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_BASE &&
                this.selectedTipoRiparazione.id !== this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_BASE) || this.matricolaValvola.length > 0);
    }

    onDownloadDocumento(documentoId: number) {
        this.guastiService.getDocumentoDownloadUrl(documentoId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onDocumentoChange(event: any) {
        this.documento = event.target.files[0];
    }

    uploadDocumento() {
        this.guastiService.aggiungiDocumento(this.id, this.documento, new GuastoDocumentoCommand(this.id, this.documento.name))
            .subscribe((result) => {
                this.refreshDocumenti();
                this.documento = null;
            }
            );
    }

    refreshDocumenti() {
        this.guastiService.getDocumenti(this.id).subscribe(documenti => {
            this.documenti = documenti;
        });
    }

    onDeleteDocumento(doc: GuastoDocumento) {
        if (confirm('Confermi la cancellazione?')) {
            this.guastiService.eliminaDocumento(this.id, doc.id.toString()).subscribe((result) => {
                this.refreshDocumenti();
            });
        }
    }

    filteredTipiRiparazione(): Array<TipoRiparazione> {
        return this.tipiRiparazione.filter(t => {
            return this.riparazioneDaCercare != '' ? t.nome.toLowerCase().indexOf(this.riparazioneDaCercare.toLowerCase()) >= 0 : true;
        });
    }

    showDescrizione(): boolean {
        return this.selectedTipoRiparazione &&
            (this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_BOMBOLA_ALTRO || this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_ALTRO
                || this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_ALTRO);
    }

    showMatricola(): boolean {
        return this.selectedTipoRiparazione && this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_BOMBOLA_SOST_VALVOLA;
    }

    showNumeroSeriale(): boolean {
        return this.selectedTipoRiparazione && (
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_VALVOLA_SECONDARIA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_VALVOLA_SECONDARIA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_INNESTO_CARICA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_INNESTO_CARICA ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_EROGATORE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_EROGATORE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_VALVOLE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_VALVOLE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_DEWAR_SOST_BASE ||
                                     this.selectedTipoRiparazione.id == this.globals.TIPO_RIPARAZIONE_STROLLER_SOST_BASE);
    }
}
