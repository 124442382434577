export class InizioRiparazioneGuastoDto {
    dataInizioRiparazione: Date;
    note: string;

    constructor(
        dataInizioRiparazione: Date,
        note: string
    ) {
        this.dataInizioRiparazione = dataInizioRiparazione;
        this.note = note;
    }
}
