import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ManutentoriService} from '../../../core/manutentori.service';
import {Manutentore} from '../../../model/query/manutentore';
import {ManutentoreCreateCommand} from '../../../model/command/manutentore-create-command';
import {ManutentoreUpdateCommand} from '../../../model/command/manutentore-update-command';

@Component({
    selector: 'app-manutentore-detail',
    templateUrl: './manutentore-detail.component.html',
    styleUrls: ['./manutentore-detail.component.css']
})
export class ManutentoreDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertClosed = true;
    alertMessage: String;

    constructor(
        private route: ActivatedRoute,
        private manutentoriService: ManutentoriService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        if (this.id !== 'new') {
            this.manutentoriService.get(this.id).subscribe(entity => {
                this.initializeFormFields(entity);
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        if (!this.id || this.id === 'new') {
            const command: ManutentoreCreateCommand =
                new ManutentoreCreateCommand(formValue.nome, formValue.cognome, formValue.username, formValue.email, formValue.esterno);
            this.manutentoriService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Manutentore creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            const command: ManutentoreUpdateCommand = new ManutentoreUpdateCommand(formValue.nome, formValue.cognome, formValue.esterno);
            this.manutentoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Manutentore aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.required, Validators.maxLength(255), Validators.email]],
            esterno: [false]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Manutentore): void {
        let formValues;

        if (entity) {
            formValues = {
                nome: entity.nome,
                cognome: entity.cognome,
                username: entity.account.username,
                email: entity.account.email,
                esterno: entity.esterno,
            };
            this.form.get('username').disable();
            this.form.get('email').disable();
        } else {
            formValues = {
                nome: '',
                cognome: '',
                username: '',
                email: '',
                esterno: false
            };
        }
        this.form.setValue(formValues);
    }

}
