import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Manutentore} from '../model/query/manutentore';

@Injectable()
export class ManutentoriService extends BaseService<Manutentore> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('manutentori');
    }
}
