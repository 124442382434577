import {AccountCommand} from './account-command';

export class AddettoProduzioneCreateCommand extends AccountCommand {

    addettoProduzione: any;

    constructor(nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.addettoProduzione = {
            'nome': nome,
            'cognome': cognome,
        };
    }
}
