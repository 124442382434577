import {Component} from '@angular/core';
import {ContenitoriBaseComponent} from '../contenitori-base.component';
import {Router} from '@angular/router';
import {GuastiService} from '../../core/guasti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-contenitori-da-riparare',
    templateUrl: './contenitori-da-riparare.component.html',
    styleUrls: ['./contenitori-da-riparare.component.css']
})
export class ContenitoriDaRiparareComponent extends ContenitoriBaseComponent {

    constructor(
        guastiService: GuastiService,
        dialog: DialogService,
        router: Router
    ) {
        super(guastiService, router, 1, '/contenitori-da-riparare/', dialog);
    }
}
