import {Component} from '@angular/core';
import {ContenitoriBaseComponent} from '../contenitori-base.component';
import {Router} from '@angular/router';
import {GuastiService} from '../../core/guasti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-contenitori',
    templateUrl: './contenitori.component.html',
    styleUrls: ['./contenitori.component.css']
})
export class ContenitoriComponent extends ContenitoriBaseComponent {
    constructor(
        guastiService: GuastiService,
        dialog: DialogService,
        router: Router
    ) {
        super(guastiService, router, -1, '/contenitori/', dialog);
    }
}
