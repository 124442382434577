import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {GuastiService} from '../../../core/guasti.service';
import {UtilsService} from '../../../core/utils.service';
import {Guasto} from '../../../model/query/guasto';
import {GuastoNota} from '../../../model/query/guasto-nota';
import {InizioRiparazioneGuastoDto} from '../../../model/command/inizio-riparazione-guasto-dto';

@Component({
    selector: 'app-contenitore-da-riparare-detail',
    templateUrl: './contenitore-da-riparare-detail.component.html',
    styleUrls: ['./contenitore-da-riparare-detail.component.css']
})
export class ContenitoreDaRiparareDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    guasto: Guasto;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    tipiGuasto: any = new Array<any>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public guastiService: GuastiService,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.guastiService.get(this.id).subscribe((guasto) => {
            this.guasto = guasto;
            this.initializeFormFields();
            this.guastiService.getTipiGuasto(this.id).subscribe(gtg => {
                for (const obj of gtg) {
                    this.tipiGuasto.push({tipoGuasto: obj.tipoGuasto.nome, descrizione: obj.descrizione});
                }
            });
        });
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();

        const command = new InizioRiparazioneGuastoDto(this.utilsService.fieldToDate(formValue.dataInizioRiparazione), formValue.note);

        this.guastiService.iniziaRiparazione(this.id, command).subscribe((res) => {
            this.submitButton = ClrLoadingState.DEFAULT;
            if (!res) {
                this.alertMessage = 'Inizio riparazione guasto effettuato!';
                this.alertClosed = false;
                this.router.navigate(['/contenitori-in-riparazione/' + this.id]);
            }
        });
    }

    createForm(): void {
        const formGroup = {
            tipologia: [{ value: '', disabled: true }],
            modello: [{ value: '', disabled: true }],
            matricola: [{ value: '', disabled: true }],
            createdOn: [{ value: '', disabled: true }],
            dataApertura: [{ value: '', disabled: true }],
            utenteApertura: [{ value: '', disabled: true }],
            notaApertura: [{ value: '', disabled: true }],
            note: '',
            dataInizioRiparazione: [{ value: '', disabled: true }, [Validators.required]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(): void {
        let formValues;

        formValues = {
            tipologia: this.guastiService.TIPO_CONTENITORE[this.guasto.tipologia],
            modello: this.guasto.modello,
            matricola: this.guasto.matricola,
            createdOn: this.utilsService.dateToField(this.guasto.createdOn),
            dataApertura: this.utilsService.dateToField(this.guasto.dataApertura),
            utenteApertura: this.guasto.utenteApertura,
            notaApertura: this.guasto.notaAperturaGuasto || '',
            note: '',
            dataInizioRiparazione: this.utilsService.dateToField(new Date())
        };
        this.form.setValue(formValues);
    }

}
