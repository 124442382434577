import {Injectable} from '@angular/core';
import {catchError, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of, of as observableOf} from 'rxjs';
import {NavigationExtras, Router} from '@angular/router';
import {ResultUrl} from '../model/result-url';
import {ReportGuastiCommand} from '../model/command/report-guasti-command';
import {ReportGuastiDownloadCommand} from '../model/command/report-guasti-download-command';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    private baseApiUrl = environment.apiUrl;

    constructor(private httpClient: HttpClient, private router: Router) {
    }

    fetchReportGuasti(command: ReportGuastiCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-guasti', command).pipe(
            catchError(this.handleError('reports service - get-report-guasti', null))
        );
    }

    getReportGuastiDownloadLink(command: ReportGuastiDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-guasti', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-guasti', null))
        );
    }

    private handleError<T>(operation = 'operation', result?: T, errorMessage?: string) {
        return (error: any): Observable<T> => {

            console.error(operation + ' failed with: ' + error);
            console.log(error);

            if (error.status === 500) {
                const navigationExtras: NavigationExtras = {
                    queryParams: {'error_status': error.status, 'error_message': error.error.message}
                };
                this.router.navigate(['/error'], navigationExtras);
            } else if (errorMessage) {
                this.openErrorMessageWindow(errorMessage);
            } else {
                this.openErrorMessageWindow(error.error ? error.error.message : 'Error ' + error.status);
            }
            return observableOf(result as T);
        };
    }

    private openErrorMessageWindow(message: string): void {
        alert(message);
    }

}
