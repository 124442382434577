import {AbstractCommand} from './abstract-command';

export class MagazziniereUpdateCommand extends AbstractCommand {

    nome: string;
    cognome: string;

    constructor(nome: string, cognome: string) {
        super();
        this.nome = nome;
        this.cognome = cognome;
    }

}
