import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AmministratoreDetailComponent} from './ui/amministratori/amministratore-detail/amministratore-detail.component';
import {AmministratoriComponent} from './ui/amministratori/amministratori.component';
import {AddettoConsegnaDetailComponent} from './ui/addetti-consegna/addetto-consegna-detail/addetto-consegna-detail.component';
import {AddettiConsegnaComponent} from './ui/addetti-consegna/addetti-consegna.component';
import {AddettoProduzioneDetailComponent} from './ui/addetti-produzione/addetto-produzione-detail/addetto-produzione-detail.component';
import {AddettiProduzioneComponent} from './ui/addetti-produzione/addetti-produzione.component';
import {ResponsabileProduzioneDetailComponent} from './ui/responsabili-produzione/responsabile-produzione-detail/responsabile-produzione-detail.component';
import {ResponsabiliProduzioneComponent} from './ui/responsabili-produzione/responsabili-produzione.component';
import {ManutentoreDetailComponent} from './ui/manutentori/manutentore-detail/manutentore-detail.component';
import {ManutentoriComponent} from './ui/manutentori/manutentori.component';
import {MagazziniereDetailComponent} from './ui/magazzinieri/magazziniere-detail/magazziniere-detail.component';
import {MagazzinieriComponent} from './ui/magazzinieri/magazzinieri.component';
import {ErrorComponent} from './error.component';
import {ReportGuastiComponent} from './ui/reports/report-guasti/report-guasti.component';
import {PasswordComponent} from './ui/password/password.component';
import {ContenitoriGuastiComponent} from './ui/contenitori-guasti/contenitori-guasti.component';
import {ContenitoreGuastoDetailComponent} from './ui/contenitori-guasti/contenitore-guasto-detail/contenitore-guasto-detail.component';
import {ContenitoriDaRiparareComponent} from './ui/contenitori-da-riparare/contenitori-da-riparare.component';
import {ContenitoreDaRiparareDetailComponent} from './ui/contenitori-da-riparare/contenitore-da-riparare-detail/contenitore-da-riparare-detail.component';
import {ContenitoriInRiparazioneComponent} from './ui/contenitori-in-riparazione/contenitori-in-riparazione.component';
import {ContenitoreInRiparazioneDetailComponent} from './ui/contenitori-in-riparazione/contenitore-in-riparazione-detail/contenitore-in-riparazione-detail.component';
import {ContenitoriDaVerificareComponent} from './ui/contenitori-da-verificare/contenitori-da-verificare.component';
import {ContenitoreDaVerificareDetailComponent} from './ui/contenitori-da-verificare/contenitore-da-verificare-detail/contenitore-da-verificare-detail.component';
import {ContenitoriVerificatiComponent} from './ui/contenitori-verificati/contenitori-verificati.component';
import {ContenitoreVerificatoDetailComponent} from './ui/contenitori-verificati/contenitore-verificato-detail/contenitore-verificato-detail.component';
import {ContenitoriComponent} from './ui/contenitori/contenitori.component';
import {ContenitoreDetailComponent} from './ui/contenitori/contenitore-detail/contenitore-detail.component';

const routes: Routes = [
    {
        path: 'amministratori/:id',
        component: AmministratoreDetailComponent
    },
    {
        path: 'amministratori',
        component: AmministratoriComponent
    },
    {
        path: 'addetti-consegna/:id',
        component: AddettoConsegnaDetailComponent
    },
    {
        path: 'addetti-consegna',
        component: AddettiConsegnaComponent
    },
    {
        path: 'addetti-produzione/:id',
        component: AddettoProduzioneDetailComponent
    },
    {
        path: 'addetti-produzione',
        component: AddettiProduzioneComponent
    },
    {
        path: 'responsabili-produzione/:id',
        component: ResponsabileProduzioneDetailComponent
    },
    {
        path: 'responsabili-produzione',
        component: ResponsabiliProduzioneComponent
    },
    {
        path: 'magazzinieri/:id',
        component: MagazziniereDetailComponent
    },
    {
        path: 'magazzinieri',
        component: MagazzinieriComponent
    },
    {
        path: 'manutentori/:id',
        component: ManutentoreDetailComponent
    },
    {
        path: 'manutentori',
        component: ManutentoriComponent
    },
    {
        path: 'contenitori-guasti',
        component: ContenitoriGuastiComponent
    },
    {
        path: 'contenitori-guasti/:id',
        component: ContenitoreGuastoDetailComponent
    },
    {
        path: 'contenitori-da-riparare',
        component: ContenitoriDaRiparareComponent
    },
    {
        path: 'contenitori-da-riparare/:id',
        component: ContenitoreDaRiparareDetailComponent
    },
    {
        path: 'contenitori-in-riparazione',
        component: ContenitoriInRiparazioneComponent
    },
    {
        path: 'contenitori-in-riparazione/:id',
        component: ContenitoreInRiparazioneDetailComponent
    },
    {
        path: 'contenitori-da-verificare',
        component: ContenitoriDaVerificareComponent
    },
    {
        path: 'contenitori-da-verificare/:id',
        component: ContenitoreDaVerificareDetailComponent
    },
    {
        path: 'contenitori-verificati',
        component: ContenitoriVerificatiComponent
    },
    {
        path: 'contenitori-verificati/:id',
        component: ContenitoreVerificatoDetailComponent
    },
    {
        path: 'contenitori',
        component: ContenitoriComponent
    },
    {
        path: 'contenitori/:id',
        component: ContenitoreDetailComponent
    },
    {
        path: 'reports/guasti',
        component: ReportGuastiComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: 'password',
        component: PasswordComponent
    },
    {path: '', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
