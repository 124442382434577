import {AbstractCommand} from './abstract-command';

export class ManutentoreUpdateCommand extends AbstractCommand {
    nome: string;
    cognome: string;
    esterno: boolean;
    
    constructor(nome: string, cognome: string, esterno: boolean) {
        super();
        this.nome = nome;
        this.cognome = cognome;
        this.esterno = esterno;
    }

}
