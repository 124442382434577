import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AuthInterceptor} from './auth.interceptor';
import {UploadService} from './upload.service';
import {MeService} from './me.service';
import {AmministratoriService} from './amministratori.service';
import {UtilsService} from './utils.service';
import {Globals} from './globals.service';
import {AddettiConsegnaService} from './addetti-consegna.service';
import {AddettiProduzioneService} from './addetti-produzione.service';
import {ResponsabiliProduzioneService} from './responsabili-produzione.service';
import {ManutentoriService} from './manutentori.service';
import {MagazzinieriService} from './magazzinieri.service';
import {GuastiService} from './guasti.service';

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        UploadService,
        MeService,
        AmministratoriService,
        AddettiConsegnaService,
        AddettiProduzioneService,
        ResponsabiliProduzioneService,
        ManutentoriService,
        MagazzinieriService,
        UtilsService,
        GuastiService,
        Globals
    ]
})
export class CoreModule {
}
