import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Magazziniere} from '../../model/query/magazziniere';
import {Router} from '@angular/router';
import {MagazzinieriService} from '../../core/magazzinieri.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-magazzinieri',
    templateUrl: './magazzinieri.component.html',
    styleUrls: ['./magazzinieri.component.css']
})
export class MagazzinieriComponent extends DatagridComponent<Magazziniere> {

    constructor(
        private magazzinieriService: MagazzinieriService,
        dialog: DialogService,
        router: Router
    ) {
        super(magazzinieriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/magazzinieri/';
    }

}
