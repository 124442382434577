import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {AddettiProduzioneService} from '../../../core/addetti-produzione.service';
import {AddettoProduzione} from '../../../model/query/addetto-produzione';
import {AddettoProduzioneCreateCommand} from '../../../model/command/addetto-produzione-create-command';
import {AddettoProduzioneUpdateCommand} from '../../../model/command/addetto-produzione-update-command';

@Component({
    selector: 'app-addetto-produzione-detail',
    templateUrl: './addetto-produzione-detail.component.html',
    styleUrls: ['./addetto-produzione-detail.component.css']
})
export class AddettoProduzioneDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertClosed = true;
    alertMessage: String;

    constructor(
        private route: ActivatedRoute,
        private addettiProduzioneService: AddettiProduzioneService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        if (this.id !== 'new') {
            this.addettiProduzioneService.get(this.id).subscribe(entity => {
                this.initializeFormFields(entity);
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        if (!this.id || this.id === 'new') {
            const command: AddettoProduzioneCreateCommand =
                new AddettoProduzioneCreateCommand(formValue.nome, formValue.cognome, formValue.username, formValue.email);
            this.addettiProduzioneService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Addetto produzione creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            const command: AddettoProduzioneUpdateCommand = new AddettoProduzioneUpdateCommand(formValue.nome, formValue.cognome);
            this.addettiProduzioneService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Addetto produzione aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.required, Validators.maxLength(255), Validators.email]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: AddettoProduzione): void {
        let formValues;

        if (entity) {
            formValues = {
                nome: entity.nome,
                cognome: entity.cognome,
                username: entity.account.username,
                email: entity.account.email
            };
            this.form.get('username').disable();
            this.form.get('email').disable();
        } else {
            formValues = {
                nome: '',
                cognome: '',
                username: '',
                email: ''
            };
        }
        this.form.setValue(formValues);
    }

}
