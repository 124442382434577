import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {AddettoConsegna} from '../../model/query/addetto-consegna';
import {Router} from '@angular/router';
import {AddettiConsegnaService} from '../../core/addetti-consegna.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-addetti-consegna',
    templateUrl: './addetti-consegna.component.html',
    styleUrls: ['./addetti-consegna.component.css']
})
export class AddettiConsegnaComponent extends DatagridComponent<AddettoConsegna> {

    constructor(
        private addettiConsegnaService: AddettiConsegnaService,
        dialog: DialogService,
        router: Router
    ) {
        super(addettiConsegnaService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/addetti-consegna/';
    }

}
