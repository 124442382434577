import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {GuastiService} from '../../../core/guasti.service';
import {UtilsService} from '../../../core/utils.service';
import {Guasto} from '../../../model/query/guasto';
import {TipoGuasto} from '../../../model/query/tipo-guasto';
import {AperturaGuastoDto} from '../../../model/command/apertura-guasto-dto';

@Component({
    selector: 'app-contenitore-guasto-detail',
    templateUrl: './contenitore-guasto-detail.component.html',
    styleUrls: ['./contenitore-guasto-detail.component.css']
})
export class ContenitoreGuastoDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    guasto: Guasto;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    leftTipiGuasto: TipoGuasto[] = new Array<TipoGuasto>();
    filteredLeftTipiGuasto: TipoGuasto[] = new Array<TipoGuasto>();
    rightTipiGuasto: TipoGuasto[] = new Array<TipoGuasto>();
    selectedLeftTipiGuasto: TipoGuasto[] = new Array<TipoGuasto>();
    selectedRightTipiGuasto: TipoGuasto[] = new Array<TipoGuasto>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public guastiService: GuastiService,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.guastiService.get(this.id).subscribe((guasto) => {
            this.guasto = guasto;
            this.initializeFormFields();
            this.guastiService.getElencoTipiGuasto(guasto.tipologia).subscribe(tipiGuasto => {
                this.leftTipiGuasto = tipiGuasto.objects;
                this.sortTipiGuasto();
                this.guastiService.getTipiGuasto(this.id).subscribe(gtg => {
                    for (const obj of gtg) {
                        this.rightTipiGuasto.push(obj.tipoGuasto);
                        this.utilsService.removeFromArray(obj.tipoGuasto, this.leftTipiGuasto);
                    }
                    this.filteredLeftTipiGuasto = this.leftTipiGuasto;
                });
            });
        });
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const tipiGuastoIds = this.rightTipiGuasto.map((obj) => {
            return obj.id;
        });
        const descrizioni = this.rightTipiGuasto.map((obj) => {
            return obj.nome == 'Altro' ? formValue.descrizione : '';
        });

        const command = new AperturaGuastoDto(
            this.utilsService.fieldToDate(formValue.dataApertura), formValue.note, tipiGuastoIds, descrizioni
        );

        this.guastiService.apri(this.id, command).subscribe((res) => {
            this.submitButton = ClrLoadingState.DEFAULT;
            if (!res) {
                this.alertMessage = 'Apertura guasto effettuata!';
                this.alertClosed = false;
                this.router.navigate(['/contenitori-guasti']);
            }
        });
    }

    createForm(): void {
        const formGroup = {
            tipologia: [{ value: '', disabled: true }],
            modello: [{ value: '', disabled: true }],
            matricola: [{ value: '', disabled: true }],
            createdOn: [{ value: '', disabled: true }],
            dataApertura: [{ value: '', disabled: true }, [Validators.required]],
            note: '',
            descrizione: ''
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(): void {
        let formValues;

        formValues = {
            tipologia: this.guastiService.TIPO_CONTENITORE[this.guasto.tipologia],
            modello: this.guasto.modello,
            matricola: this.guasto.matricola,
            createdOn: this.utilsService.dateToField(this.guasto.createdOn),
            dataApertura: this.utilsService.dateToField(new Date()),
            note: '',
            descrizione: ''
        };
        this.form.setValue(formValues);
    }

    swapTipiGuasto() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftTipiGuasto,
            leftSelectedArray: this.selectedLeftTipiGuasto,
            rightArray: this.rightTipiGuasto,
            rightSelectedArray: this.selectedRightTipiGuasto
        });
        this.leftTipiGuasto = model.leftArray;
        this.selectedLeftTipiGuasto = model.leftSelectedArray;
        this.rightTipiGuasto = model.rightArray;
        this.selectedRightTipiGuasto = model.rightSelectedArray;
        this.filteredLeftTipiGuasto = this.leftTipiGuasto;
        this.sortTipiGuasto();
    }

    isTipoGuastoOtherSelected() : boolean {
        return (this.selectedLeftTipiGuasto.length == 1 && this.selectedLeftTipiGuasto[0].nome == 'Altro') || (this.selectedRightTipiGuasto.length == 1 && this.selectedRightTipiGuasto[0].nome == 'Altro');
    }
    
    onCercaGuasto(value: string): void {
        this.filteredLeftTipiGuasto = this.leftTipiGuasto.filter(t => t.nome.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    }
    
    sortTipiGuasto(): void {
        this.leftTipiGuasto.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
    }
}
