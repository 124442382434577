import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {AddettoProduzione} from '../model/query/addetto-produzione';

@Injectable()
export class AddettiProduzioneService extends BaseService<AddettoProduzione> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('addetti-produzione');
    }
}
