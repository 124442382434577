export class VerificaGuastoDto {
    dataVerifica: Date;
    note: string;

    constructor(
        dataVerifica: Date,
        note: string
    ) {
        this.dataVerifica = dataVerifica;
        this.note = note;
    }
}
