import {Component} from '@angular/core';
import {ContenitoriBaseComponent} from '../contenitori-base.component';
import {Router} from '@angular/router';
import {GuastiService} from '../../core/guasti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-contenitori-guasti',
    templateUrl: './contenitori-guasti.component.html',
    styleUrls: ['./contenitori-guasti.component.css']
})
export class ContenitoriGuastiComponent extends ContenitoriBaseComponent {

    constructor(
        guastiService: GuastiService,
        dialog: DialogService,
        router: Router
    ) {
        super(guastiService, router, 0, '/contenitori-guasti/', dialog);
    }
}
