export class ReportGuastiCommand {
    from: Date;
    to: Date;
    tipoContenitore: number;
    modello: string;
    matricola: string;

    constructor(
        from: Date,
        to: Date,
        tipoContenitore: number,
        modello: string,
        matricola: string
    ) {
        this.from = from;
        this.to = to;
        this.tipoContenitore = tipoContenitore;
        this.modello = modello;
        this.matricola = matricola;
    }
}
