import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {ResponsabileProduzione} from '../../model/query/responsabile-produzione';
import {Router} from '@angular/router';
import {ResponsabiliProduzioneService} from '../../core/responsabili-produzione.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-responsabili-produzione',
    templateUrl: './responsabili-produzione.component.html',
    styleUrls: ['./responsabili-produzione.component.css']
})
export class ResponsabiliProduzioneComponent extends DatagridComponent<ResponsabileProduzione> {

    constructor(
        private responsabiliProduzioneService: ResponsabiliProduzioneService,
        dialog: DialogService,
        router: Router
    ) {
        super(responsabiliProduzioneService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/responsabili-produzione/';
    }

}
