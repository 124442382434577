import {AccountCommand} from './account-command';

export class AddettoConsegnaCreateCommand extends AccountCommand {

    addettoConsegna: any;

    constructor(nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.addettoConsegna = {
            'nome': nome,
            'cognome': cognome,
        };
    }
}
