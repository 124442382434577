export class AperturaGuastoDto {
    dataApertura: Date;
    note: string;
    tipiGuastoIds: Array<number>;
    descrizioni: Array<string>;

    constructor(
        dataApertura: Date,
        note: string,
        tipiGuastoIds: Array<number>,
        descrizioni: Array<string>
    ) {
        this.dataApertura = dataApertura;
        this.note = note;
        this.tipiGuastoIds = tipiGuastoIds;
        this.descrizioni = descrizioni;
    }
}
