import {Action} from '../model/action';
import {Observable} from 'rxjs';
import {DatagridComponent} from './common/datagrid.component';
import {ClrDatagridStateInterface} from '@clr/angular';
import {QueryResult} from '../model/query/query-result';
import {Guasto} from '../model/query/guasto';
import {Router} from '@angular/router';
import {GuastiService} from '../core/guasti.service';
import { Directive } from "@angular/core";
import {DialogService} from './dialog';

@Directive()
export abstract class ContenitoriBaseComponent extends DatagridComponent<Guasto> {
    public STATI_GUASTO: Array<string> = ['Inserito', 'Aperto', 'Riparazione', 'Riparato', 'Verificato'];
    
    matricolaDaCercare: string;
    statoQuery: number;
    editUrl: string
    
    constructor(
        public guastiService: GuastiService,
        router: Router,
        statoQuery: number,
        editUrl: string,
        dialog: DialogService
    ) {
        super(guastiService, router, dialog);
        this.statoQuery = statoQuery;
        this.editUrl = editUrl;
    }

    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<Guasto>> {
        let i = fields.indexOf('esitoPositivo');
        
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i] === '%true%';
        }
        i = fields.indexOf('dataApertura');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('dataInizioRiparazione');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('dataFineRiparazione');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('dataVerifica');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('tipologia');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('stato');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        if (this.statoQuery != -1) {
            fields.push('stato');
            operators.push('eq');
            values.push(this.statoQuery);
        }
        if (this.matricolaDaCercare) {
            fields.push('matricola');
            operators.push('eq');
            values.push(this.matricolaDaCercare);
        }
        if (!state.sort) {
            state.sort = {'by': 'createdOn', 'reverse': false};
        }
        return super.fetchData(state, fields, operators, values);
    }

    onAction(entity: Guasto, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }

    protected getEditUrl(): string {
        return this.editUrl;
    }

    onCercaMatricola(value: string): void {
        this.matricolaDaCercare = value;
        this.refresh(this.datagridState);
    }
}
