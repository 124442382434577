import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of as observableOf} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {QueryResult} from '../model/query/query-result';
import {GetAllRequest} from '../model/get-all-request';
import {AbstractCommand} from '../model/command/abstract-command';

export class BaseService<E> {
    protected baseApiUrl = environment.apiUrl;
    protected apiUrl = environment.apiUrl;

    constructor(
        protected httpClient: HttpClient,
        protected router: Router
    ) {
    }

    protected setApiUrl(url: string) {
        this.apiUrl += url;
    }

    public get(id: string): Observable<E> {
        return this.httpClient.get<E>(this.apiUrl + '/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get', null))
        );
    }

    public create(command: AbstractCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    public update(id: string, command: AbstractCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + id, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    public delete(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    public getAll(
        page: number,
        size: number,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>,
        sort: string,
        sortType: string
    ): Observable<QueryResult<E>> {
        return this.httpClient.post<QueryResult<E>>(
            this.apiUrl + '/get-all',
            new GetAllRequest(fields, operators, values, sort, sortType, page, size)).pipe(
            catchError(this.handleError(this.apiUrl, new QueryResult<E>()))
        );
    }

    protected handleError<T>(operation = 'operation', result?: T, errorMessage?: string) {
        return (error: any): Observable<T> => {
            console.error(operation + ' failed with: ' + error);
            if (error.status === 500) {
                let msg: string = error.error.message;
                
                if (msg.indexOf('ConstraintViolationException') >= 0) {
                    msg = "Impossibile eseguire l'operazione, violazione vincoli di integrità dei dati";
                }
                this.openErrorMessageWindow('Error ' + error.status + ': ' + msg);
            } else if (errorMessage) {
                this.openErrorMessageWindow(errorMessage);
            } else {
                this.openErrorMessageWindow(error.error ? error.error.message : "Error " + error.status);
            }
            return observableOf(error);
        };
    }

    protected extractIdFromLocation(response: any): number {
        const location: string = response.headers.get('Location');
        const index: number = location.lastIndexOf('/');

        return location && index !== -1 ? parseInt(location.substr(index + 1), null) : undefined;
    }

    private openErrorMessageWindow(message: string): void {
        alert(message);
    }
}
